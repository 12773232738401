<template>
    <b-modal :id="'modal-' + type" :title="modalTitle" hide-footer size="lg" centered>
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="title">Titre de la prestation</label>
                            <input
                                v-model="service.title"
                                type="text"
                                class="form-control"
                                id="title"
                                placeholder="Entrer un titre"
                                name="title"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="title">Description de la prestation</label>
                            <textarea
                                v-model="service.description"
                                class="form-control"
                                placeholder="Entrer une description"
                                rows="3"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="price">Prix</label>
                        <div class="input-group">
                            <currency-input 
                                v-model="service.price"
                            ></currency-input>
                            <div class="input-group-append">
                                <span class="input-group-text">€</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="vat">Taux de TVA</label>
                            <div class="input-group">
                                <currency-input 
                                    v-model="service.vat"
                                ></currency-input>
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="unit">Unité</label>
                            <select 
                                v-model="service.unit"
                                class="form-select"
                            >
                                <option value="">Quantité</option>
                                <option value="fixed">Forfait</option>
                                <option value="m²">Mètres carrés</option>
                                <option value="ml">Mètres linéaires</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="vat">Code article</label>
                            <input
                                v-model="service.code"
                                type="text"
                                class="form-control"
                                id="code"
                                placeholder="Entrer un code article"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="subject">Type</label>
                            <select class="form-select" v-model="service.type">
                                <option value="main">Principale</option>
                                <option value="secondary">Annexe</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="status">Catégorie</label>
                            <select 
                                v-model="chosenCategory"
                                class="form-select"
                            >
                                <option value=""></option>
                                <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-end">
                            <button @click.prevent="createService()" class="btn btn-primary" v-if="type == 'add'">
                                Ajouter la prestation
                                <i class="uil uil-plus ms-2"></i>
                            </button>
                            <button @click.prevent="updateService()" class="btn btn-primary" v-else-if="type == 'update'">
                                Modifier la prestation
                                <i class="uil uil-pen ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
    import Swal from "sweetalert2";
    import api from '../api/api';
    import Service from '../models/service';
    import Utilities from "../services/utilities";

    export default {
        name: 'ServiceForm',
        data() {
            return {
                submitted: false,
                errors: [],
                service: new Service('', '', 20, '', '', '', 'main', null),
                modalTitle: '',
                categories: [],
                chosenCategory: null
            }
        },
        props: ['type', 'selectedService'],
        mounted() {
            if (this.type == 'update') {
                this.service = {...this.selectedService};
                this.chosenCategory = this.service.category ? this.service.category.id : null;
                this.modalTitle = 'Modifier une prestation';
            } else {
                this.modalTitle = 'Ajouter une prestation';
            }
            this.loadServiceCategories();
        },
        watch: {
            selectedService(data) {
                this.service = {...data};
                this.chosenCategory = this.service.category ? this.service.category.id : null;
            }
        },
        methods: {
            loadServiceCategories() {
                api
                .get('/service_categories')
                .then(
                    (response) => {
                        if (response && response.status == 200) {
                            if (Array.isArray(response.data)) {
                                this.categories = response.data;
                                this.loading = false;
                            }
                        }
                    },
                    () => {
                        Utilities.errormsg();
                    }
                )
            },
            serviceValid() {
                this.errors = [];
    
                if (!this.service.title) {
                    this.errors.push('Le titre est obligatoire');
                }

                if (!this.service.code) {
                    this.errors.push('Le code est obligatoire');
                }

                if (!this.service.price) {
                    this.errors.push('Le prix est obligatoire');
                }
    
                if (!this.service.vat) {
                    this.errors.push('La TVA est obligatoire');
                }
    
                if (!this.service.type) {
                    this.errors.push('Le type est obligatoire');
                }
    
                if (!this.chosenCategory || this.chosenCategory == '') {
                    this.errors.push('La catégorie est obligatoire');
                }
    
                if (this.errors.length == 0) {
                    return true;
                } else {
                    Swal.fire("Erreur", this.errors[0], "error");
                    return false;
                }
            },
            createService: function () {
                if (this.serviceValid()) {
                    this.service.category = (this.chosenCategory && this.chosenCategory != '') ? '/api/service_categories/' + this.chosenCategory : null;

                    api
                        .post('/services', this.service)
                        .then(
                            (response) => {
                                if (response && response.status == 201) {
                                    Utilities.successmsg("La prestation a bien été ajoutée !");
                                    this.service = new Service('', '', 20, '', '', '');
                                    this.$emit('services-updated', {type: this.type});
                                }
                            },
                            () => {
                                Utilities.errormsg();
                            }
                        )
                }
            },
            updateService: function () {
                if (this.serviceValid()) {
                    let serviceToSend = {...this.service};
                    serviceToSend.category = (this.chosenCategory && this.chosenCategory != '') ? '/api/service_categories/' + this.chosenCategory : null;
                    delete serviceToSend.id,

                    api
                        .patch('/services/' + this.service.id, serviceToSend, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json'
                            }
                        })
                        .then(
                            (response) => {
                                if (response && response.status == 200) {
                                    Utilities.successmsg("La prestation a bien été modifiée !");
                                    this.service = new Service('', '', 20, '', '', '');
                                    this.$emit('services-updated', { type: this.type });
                                }
                            },
                            () => {
                                Utilities.errormsg();
                            }
                        )
                }
            },
        }
    }
</script>

<style lang="scss">
    .input-group > div:first-child {
        width: calc(100% - 40px);
    }

    .input-group-append {
        width: 40px;
    }

    .input-group-text {
        justify-content: center;
    }
</style>