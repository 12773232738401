export default class Service {
    constructor(
        title,
        price,
        vat,
        description,
        unit,
        code,
        type,
        category
    ) {
        this.title = title;
        this.price = price;
        this.vat = vat;
        this.description = description;
        this.unit = unit;
        this.code = code;
        this.type = type;
        this.category = category;
    }
}